exports.components = {
  "component---src-pages-preview-buffet-donation-tsx": () => import("./../../../src/pages/__preview/buffet_donation.tsx" /* webpackChunkName: "component---src-pages-preview-buffet-donation-tsx" */),
  "component---src-pages-preview-location-tsx": () => import("./../../../src/pages/__preview/location.tsx" /* webpackChunkName: "component---src-pages-preview-location-tsx" */),
  "component---src-pages-preview-page-tsx": () => import("./../../../src/pages/__preview/page.tsx" /* webpackChunkName: "component---src-pages-preview-page-tsx" */),
  "component---src-pages-preview-scrollover-tsx": () => import("./../../../src/pages/__preview/scrollover.tsx" /* webpackChunkName: "component---src-pages-preview-scrollover-tsx" */),
  "component---src-pages-preview-simple-donation-tsx": () => import("./../../../src/pages/__preview/simple_donation.tsx" /* webpackChunkName: "component---src-pages-preview-simple-donation-tsx" */),
  "component---src-pages-preview-sponsorship-donation-tsx": () => import("./../../../src/pages/__preview/sponsorship_donation.tsx" /* webpackChunkName: "component---src-pages-preview-sponsorship-donation-tsx" */),
  "component---src-pages-preview-stock-donation-tsx": () => import("./../../../src/pages/__preview/stock_donation.tsx" /* webpackChunkName: "component---src-pages-preview-stock-donation-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-content-home-tsx": () => import("./../../../src/templates/content-home.tsx" /* webpackChunkName: "component---src-templates-content-home-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-donation-page-tsx": () => import("./../../../src/templates/donation-page.tsx" /* webpackChunkName: "component---src-templates-donation-page-tsx" */),
  "component---src-templates-email-confirm-page-tsx": () => import("./../../../src/templates/email-confirm-page.tsx" /* webpackChunkName: "component---src-templates-email-confirm-page-tsx" */)
}

