import { createStore } from "zustand";
import { persist } from 'zustand/middleware';

type BackButtonStore = {
  last_visited_content_type: string;
  show_back_button: boolean;
  update: (data: {
    last_visited_content_type?: string,
    show_back_button?: boolean,
  }) => void;
};

export const backButtonStore = createStore(
  persist<BackButtonStore>(
    (set) => ({
      last_visited_content_type: '',
      show_back_button: false,
      update: (data) => set((state) => ({
        ...state,
        ...data
      })),
    }),
    {
      name: 'back_button_store',
    }
  ));
