import { useLocation } from '@reach/router';
import produce from 'immer';
import { isEqual } from 'lodash';
import * as queryString from 'query-string';
import { createElement, Fragment, PropsWithChildren, useEffect } from 'react';
import create from 'zustand';

type ParameterState = {
  path: string | undefined;
  parameters: queryString.ParsedQuery;
  initialize: (path: string, search: string) => void;
  setParameter: (key: string, value: string) => void;
  clearParameter: (key: string) => void;
  clear: () => void;
};

export const QueryParameterWrapper = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();

  useEffect(() => {
    useParameterStore.getState().initialize(location.pathname, location.search);
    return useParameterStore.subscribe(({ parameters }) => {
      const hasKeys = Object.keys(parameters).length > 0;
      const urlSuffix = hasKeys ? `?${queryString.stringify(parameters)}` : '';
      window.history.pushState(
        window.history.state,
        '',
        `${window.location.pathname}${urlSuffix}`,
      );
    });
  }, [location]);
  return createElement(Fragment, {}, children);
};

const useParameterStore = create<ParameterState>((set) => ({
  path: undefined,
  parameters: {},
  initialize: (path: string, search: string) =>
    set(
      produce((state) => {
        state.parameters = queryString.parse(search);
        state.path = path;
      }),
    ),
  setParameter: (key: string, value: string) =>
    set(
      produce((state) => {
        state.parameters[key] = value;
      }),
    ),
  clearParameter: (key: string) =>
    set(
      produce((state) => {
        delete state.parameters[key];
      }),
    ),
  clear: () =>
    set(
      produce((state) => {
        state.parameters = {};
      }),
    ),
}));

/**
 * Retrieve a map of the decoded query params from `window.location.search`.
 */
export function useQueryParams(): queryString.ParsedQuery {
  return useParameterStore((state) => state.parameters, isEqual);
}

export function useQueryParamString(
  key: string,
  defaultValue: string,
): [string, (val: string) => void] {
  const queryParams = useQueryParams();
  const paramValue = (queryParams[key] ?? defaultValue) as string;
  const setValue = (val: string) => {
    useParameterStore.getState().setParameter(key, val);
  };
  return [paramValue, setValue];
}

/**
 * Set the query params (without affecting the router).
 */
export function setQueryParams(path: string, query: string): void {
  useParameterStore.getState().initialize(path, query);
}

/**
 * Remove a query param key from the URL entirely.
 */
export function clearQueryParam(key: string): void {
  useParameterStore.getState().clearParameter(key);
}
