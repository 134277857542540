import { createStore } from 'zustand';

import { CurrentUserQuery } from '../../generated/operation-ids';
import { useCurrentUserQuery } from '../../generated/queries/react-queries';
import { get } from './graphql-fetch';

export const useCurrentUser = () => {
  const currentUser = useCurrentUserQuery();
  return {
    currentUser: {
      ...currentUser.data?.currentUser,
      uid: parseInt(currentUser.data?.currentUser?.uid || '0'),
      isLoading: currentUser.isLoading
    },
  };
};

type UserStore = {
  // The current user uid.
  uid: number;
  // Fetch user information from the server and re-fresh the store.
  update: () => void;
};

export const currentUserStore = createStore<UserStore>((set) => ({
  uid: 0,
  update: async () => {
    set({
      uid: parseInt((await get(CurrentUserQuery)).currentUser.uid || '0'),
    });
  },
}));
