import '@caritas/ui/css';

import { GatsbyBrowser } from 'gatsby';

import { backButtonStore } from './src/commons/back';
import { currentUserStore } from './src/commons/user';
import { currentTheme } from './src/utils/theme';
import { WrapPageElement } from './src/utils/wrap-page-element';

export const onClientEntry = () => {
  window.onload = () => {
    // Immediately initialise the user store when the app gets loaded on the
    // client, but only for the bergeinsatz theme.
    if (currentTheme === 'bergeinsatz') {
      currentUserStore.getState().update();
    }
    // ====================================================================
    // Automatic reload on rebuild.
    // TODO: replace this with a "live refresh" from Drupal.
    const statusIndicator = document
      .getElementsByTagName('publisher-status')
      .item(0);
    if (statusIndicator) {
      let previousState = 'ready';
      statusIndicator.addEventListener('publisher-status', (event) => {
        if (event instanceof CustomEvent) {
          if (event.detail === 'ready' && event.detail !== previousState) {
            window.location.reload();
          }
          previousState = event.detail;
        }
      });
    }
    // ====================================================================
  };
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  // Do no use scroll restoration when the URL contains a hash.
  if (location.hash) {
    return false;
  }
  return currentPosition;
};

export const wrapPageElement = WrapPageElement;

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({prevLocation}) => {
  // Before the route gets updated, we can decide if we need to show the back
  // button on the next page. We show it if:
  // - there is a previous location (meaning the user clicked on a link on the
  //   current page)
  // - the last visited content type was a scrollover page.
  const backButtonState = backButtonStore.getState();
  backButtonState.update({
    show_back_button: prevLocation && backButtonState.last_visited_content_type === 'DrupalScrolloverPage' ? true: false,
  });
}
