/**
 * Generic execution of a persisted graphql query via GET request.
 *
 * @param id A generated operation id.
 * @returns The result of the query as a promise
 */
import { AnyOperationId, OperationResult } from '../../generated/operation-ids';

export async function get<T extends AnyOperationId>(id: T) {
  const url = new URL(process.env.GATSBY_GRAPHQL_ENDPOINT!);
  url.searchParams.append('queryId', id);
  return (
    await (
      await fetch(url.toString(), {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    ).json()
  ).data as OperationResult<T>;
}
