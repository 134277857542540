import { SilverbackPageContext } from '@amazeelabs/gatsby-source-silverback';
import { GatsbyBrowser, PageProps, WrapPageElementBrowserArgs } from 'gatsby';
import React, { PropsWithChildren, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { backButtonStore } from '../commons/back';
import { QueryParameterWrapper } from '../commons/query-string';

type Props = PropsWithChildren<{
  pageContext: SilverbackPageContext;
}>;

// Wrap everything inside a component, otherwise react hook rules
// complains about the use of useMessages during SSR.
const PageWrapper = ({ children, pageContext }: Props) => {

  useEffect(() => {
    backButtonStore.getState().update({
      last_visited_content_type: pageContext.typeName || ''
    });
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 2, // 2 hours
        staleTime: 1000 * 60 * 60 * 2, // 2 hours
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <QueryParameterWrapper>{children}</QueryParameterWrapper>
    </QueryClientProvider>
  );
};

export const WrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElementBrowserArgs<any, SilverbackPageContext> & {
  props: PageProps<any, SilverbackPageContext>;
}) => <PageWrapper pageContext={props.pageContext}>{element}</PageWrapper>;
